// src/hooks/useWasmMask.js
import { useState, useEffect, useRef } from "react";
import useWasm from "../wasm/use"; // Adjust the import path as needed

const useWasmMask = (geojson, bounds, resolution) => {
  const { wasm, error } = useWasm();
  const [mask, setMask] = useState(null);
  const [wasmError, setWasmError] = useState(null);
  const polygonPtrRef = useRef(null);

  // Effect to load the polygon once when wasm or geojson changes
  useEffect(() => {
    if (!wasm || !geojson) return;

    const loadPolygon = () => {
      try {
        // Allocate memory for the GeoJSON string
        const geojsonPtr = wasm.allocateString(JSON.stringify(geojson));

        // Load the polygon into WASM
        const polygonPtr = wasm.loadPolygon(geojsonPtr);
        if (!polygonPtr) {
          throw new Error("Failed to load polygon.");
        }

        // Store the polygonPtr in the ref
        polygonPtrRef.current = polygonPtr;

        // Free the GeoJSON string memory as it's no longer needed
        wasm.free(geojsonPtr);
      } catch (err) {
        console.error("WASM Polygon Loading Error:", err);
        setWasmError(err.message);
      }
    };

    loadPolygon();

    // Cleanup function to free polygonPtr when geojson or wasm changes/unmounts
    return () => {
      if (polygonPtrRef.current) {
        wasm.free(polygonPtrRef.current);
        polygonPtrRef.current = null;
      }
    };
  }, [wasm, geojson]);

  // Effect to generate the mask whenever bounds or resolution changes
  useEffect(() => {
    if (!wasm || !polygonPtrRef.current || !bounds || !resolution) return;

    const generateMask = () => {
      try {
        // Prepare bounds and resolution arrays
        const boundsArray = [
          parseFloat(bounds.minLat),
          parseFloat(bounds.minLng),
          parseFloat(bounds.maxLat),
          parseFloat(bounds.maxLng),
        ];
        const resolutionArray = [
          parseInt(resolution.width, 10),
          parseInt(resolution.height, 10),
        ];

        // Allocate memory for bounds and resolution
        const boundsPtr = wasm.allocateDoubleArray(boundsArray);
        const resolutionPtr = wasm.allocateIntArray(resolutionArray);

        // Call create_tensor to generate the tensor
        const tensorPtr = wasm.createTensor(
          polygonPtrRef.current,
          boundsPtr,
          resolutionPtr
        );

        if (!tensorPtr) {
          throw new Error("Failed to create tensor.");
        }

        // Read the tensor data
        const [width, height] = resolutionArray;
        const tensorSize = width * height;
        const tensorDataHeap = wasm.readIntArray(tensorPtr, tensorSize);
        const tensorArray = Array.from(tensorDataHeap);

        // Set the mask array state
        setMask(tensorArray);

        // Free allocated memory
        wasm.free(tensorPtr);
        wasm.free(boundsPtr);
        wasm.free(resolutionPtr);
      } catch (err) {
        console.error("WASM Mask Generation Error:", err);
        setWasmError(err.message);
      }
    };

    generateMask();
  }, [wasm, bounds, resolution]);

  return { mask, error: wasmError || error };
};

export default useWasmMask;
