// src/components/Statistics.js
import React from "react";
import "./Statistics.css";

const Statistics = ({
  itemsCount,
  averageSurfacePrice,
  markersCount,
  periodStart,
  periodEnd,
}) => {
  return (
    <div className="statistics-container">
      {/* Affichage de la période considérée */}
      <div className="mb-4 statistics-period">
        {periodStart} - {periodEnd}
      </div>

      {/* Table for displaying statistics */}
      <table className="min-w-full statistics-table">
        <tbody>
          <tr>
            <td>Ventes</td>
            <td style={{ textAlign: "right" }}>{itemsCount}</td>
          </tr>
          <tr>
            <td>Prix M²</td>
            <td style={{ textAlign: "right" }}>
              {averageSurfacePrice !== null
                ? `${averageSurfacePrice.toFixed(0)} €`
                : "N/A"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Statistics;
