// src/App.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import SplashScreen from "./components/SplashScreen";
import MapScreen from "./components/MapScreen";
import Sidebar from "./components/Sidebar";
import Statistics from "./components/Statistics"; // Import the Statistics component
import { useLoadScript } from "@react-google-maps/api";
import {
  Collection,
  Space,
  API,
  Network,
  OptionP,
  Progressive,
} from "js-indexus-sdk";
import "./App.css";

function App() {
  // **Load Google Maps script**
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [markers, setMarkers] = useState([]);
  const [sets, setSets] = useState([]);
  const [areas, setAreas] = useState([]);
  const [periods, setPeriods] = useState([]);

  const [indexingError, setIndexingError] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const [isSplashVisible, setIsSplashVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isMapReduced, setIsMapReduced] = useState(false);

  const [isMobile, setIsMobile] = useState(false); // Add this line

  const indexusRef = useRef(null);
  const timeRef = useRef({
    start: new Date("2019-09-01").getTime() / 1000,
    end: new Date("2020-01-01").getTime() / 1000,
  });

  const hasInitialized = useRef(false);

  const getFormattedPeriod = () => {
    const { start, end } = timeRef.current;
    const startDate = new Date(start * 1000); // Convert to milliseconds
    const endDate = new Date(end * 1000);
    // Format the dates as desired, e.g., "Nov 1, 2019"
    const options = { year: "numeric", month: "short" };
    return {
      start: startDate.toLocaleDateString(undefined, options),
      end: endDate.toLocaleDateString(undefined, options),
    };
  };

  const { start, end } = getFormattedPeriod();

  // **Initialize Indexus when isLoaded is true**
  useEffect(() => {
    if (!isLoaded) return;

    if (hasInitialized.current) return;
    hasInitialized.current = true;

    const initializeIndexus = async () => {
      try {
        const api = new API();
        const network = new Network(
          "https",
          api,
          ["bootstrap.testnet.indexus.network|21000"],
          10000
        );

        const dimensions = [
          {
            name: "gps",
            type: "spherical",
            args: [-90, 90, -180, 180],
          },
          {
            name: "time",
            type: "linear",
            args: [
              new Date("2019-01-01").getTime() / 1000,
              new Date("2024-12-31").getTime() / 1000,
            ],
          },
        ];

        const collection = new Collection(
          "DENjYsMTAyLDE2MCwxMjYsPjQ5L",
          dimensions
        );

        const space = new Space(
          collection.dimensions(),
          collection.mask(),
          collection.offset()
        );

        const option = new OptionP(0, 60, 50);

        indexusRef.current = new Progressive(
          collection,
          space,
          option,
          () => {},
          () => {},
          network
        );
      } catch (error) {
        console.error("Error initializing Indexus:", error);
        setIndexingError(error.message || "Initialization error");
      }
    };

    initializeIndexus();
  }, [isLoaded]);

  // **Detect if the device is mobile**
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check on initial load

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // **Define the refresh function**
  const refresh = useCallback(async () => {
    const indexus = indexusRef.current;

    if (!mapBounds || !indexus) {
      // Wait until mapBounds and indexus are available
      return;
    }

    setIsLoading(true); // **Start loading**

    try {
      const bounds = getBounds(indexus.space);
      const results = await indexus.search(bounds);

      if (results.items + results.sets === 0) {
        setMarkers([]);
        setSets([]);
        setAreas([]);
        setPeriods([]);
        setIsLoading(false);
        return;
      }

      const items = addPosition(results.items);
      setMarkers(items);

      const sets = addPosition(results.sets);
      setSets(sets);

      if (sets.length === 0) {
        setAreas([]);
        return;
      }

      const areas = addPosition(results.grouped[0]);
      setAreas(areas);

      const periods = addPosition(results.grouped[1]);
      setPeriods(periods);
    } catch (error) {
      console.error("Error fetching sets from Indexus:", error);
      setIndexingError(error.message || "Unknown error");
    } finally {
      setIsLoading(false); // **End loading**
    }
  }, [mapBounds]);

  // **Helper function to compute bounds for Indexus search**
  const getBounds = (space) => {
    const bounds = [
      space.dimension(0).rootSegment().value(), // [south, north, west, east]
      space.dimension(1).rootSegment().value(), // [start, end]
    ];

    if (mapBounds) {
      const ne = mapBounds.ne;
      const sw = mapBounds.sw;

      bounds[0][0] = sw.lat - (ne.lat - sw.lat) / 8;
      bounds[0][1] = ne.lat + (ne.lat - sw.lat) / 8;
      bounds[0][2] = sw.lng - (ne.lng - sw.lng) / 8;
      bounds[0][3] = ne.lng + (ne.lng - sw.lng) / 8;
    }

    if (timeRef.current) {
      bounds[1][0] = timeRef.current.start;
      bounds[1][1] = timeRef.current.end;
    }

    return bounds;
  };

  const addPosition = (elements) => {
    if (elements.length === 0) return elements;

    return elements.map((elm) => {
      elm._count = elm._count ? elm._count : 1;
      elm._position = {
        lat: elm._metrics[3] / elm._count,
        lng: elm._metrics[4] / elm._count,
      };
      return elm;
    });
  };

  // **Debounce function to limit the rate of refresh calls**
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // **Handle bounds change with debounce**
  const handleBoundsChange = useCallback(
    debounce((newBounds) => {
      setMapBounds(newBounds);
    }, 300), // **300ms debounce delay**
    []
  );

  // **Use useEffect to call refresh() when mapBounds changes**
  useEffect(() => {
    if (mapBounds) {
      refresh();
    }
  }, [mapBounds, refresh]);

  // **Handle dismissing indexingError**
  const handleDismissIndexingError = () => {
    setIndexingError(null);
  };

  // **Handle map width toggle**
  const toggleMapWidth = () => {
    setIsMapReduced((prev) => !prev);
  };

  // **Early return if maps fail to load or are still loading**
  if (loadError) return <div className="loading-error">Error loading maps</div>;
  if (!isLoaded) return <div className="loading">Loading Maps...</div>;

  // **Calculate itemsCount and averagePrice**
  const calculateStatistics = () => {
    // Calculate itemsCount: number of individual items plus total counts from sets
    const individualItemsCount = markers.length;
    const setsTotalCount = sets.reduce(
      (acc, set) => acc + (set._count || 1),
      0
    );
    const itemsCount = individualItemsCount + setsTotalCount;

    // Calculate total price from individual items
    const totalPriceItems = markers.reduce(
      (acc, item) => acc + (item._metrics[0] || 0),
      0
    );

    // Calculate total price from sets (price * count)
    const totalPriceSets = sets.reduce(
      (acc, set) => acc + (set._metrics[0] || 0),
      0
    );

    const totalPrice = totalPriceItems + totalPriceSets;
    const averagePrice = totalPrice / itemsCount;

    // Calculate total surface from individual items
    const totalSurfaceItems = markers.reduce(
      (acc, item) => acc + (item._metrics[1] || 0),
      0
    );

    // Calculate total surface from sets
    const totalSurfaceSets = sets.reduce(
      (acc, set) => acc + (set._metrics[1] || 0),
      0
    );

    const totalSurface = totalSurfaceItems + totalSurfaceSets;
    const averageSurface = totalSurface / itemsCount;

    // Calculate total price from individual items
    const totalAveragePriceItems = markers.reduce(
      (acc, item) => acc + (item._metrics[2] || 0),
      0
    );

    // Calculate total price from sets (price * count)
    const totalAveragePriceSets = sets.reduce(
      (acc, set) => acc + (set._metrics[2] || 0),
      0
    );

    // Calculate average surface price
    const averageSurfacePrice =
      (totalAveragePriceItems + totalAveragePriceSets) / itemsCount;

    return { itemsCount, averageSurface, averagePrice, averageSurfacePrice };
  };

  const { itemsCount, averageSurface, averagePrice, averageSurfacePrice } =
    calculateStatistics();

  const handleSplashFinish = () => {
    setIsSplashVisible(false);
  };

  if (isSplashVisible) {
    return <SplashScreen onFinish={handleSplashFinish} />;
  }

  return (
    <div className="app-container">
      {/* Toggle Button */}
      <button
        onClick={toggleMapWidth}
        className="fixed z-20 px-4 py-2 text-white rounded shadow bg-neutral-700 toggle-button top-4 right-4"
      >
        {isMapReduced ? "Fermer" : "Plus"}
      </button>

      {/* Statistics Component */}
      {!isMobile &&
        !isMapReduced && ( // Modify this line
          <Statistics
            itemsCount={itemsCount}
            averagePrice={averagePrice}
            averageSurface={averageSurface}
            averageSurfacePrice={averageSurfacePrice}
            markersCount={markers.length}
            setsCount={sets.length}
            areasCount={areas.length}
            periodsCount={periods.length}
            periodStart={start}
            periodEnd={end}
            isLoading={isLoading}
          />
        )}

      <div
        className={`main-content flex h-screen transition-all duration-500 ease-in-out ${
          isMapReduced ? "map-reduced" : "map-expanded"
        }`}
      >
        {/* Map Container with dynamic width */}
        <div className="flex-grow map-container">
          <MapScreen
            markers={markers}
            areas={areas}
            onBoundsChange={handleBoundsChange}
          />
        </div>

        {/* Sidebar */}
        <div
          className={`sidebar-container ${
            isMapReduced ? "sidebar-visible" : "sidebar-hidden"
          }`}
        >
          <Sidebar
            periods={periods}
            itemsCount={itemsCount}
            averagePrice={averagePrice}
            averageSurface={averageSurface}
            averageSurfacePrice={averageSurfacePrice}
            markersCount={markers.length}
            setsCount={sets.length}
            areasCount={areas.length}
            periodsCount={periods.length}
            periodStart={start}
            periodEnd={end}
          />
        </div>
      </div>

      {/* Loading Indicator */}
      {isLoading && (
        <div className="loading-indicator fixed top-4 right-4 px-3 py-1.5 bg-neutral-500 text-white rounded">
          <p className="text-sm">Loading data...</p>
        </div>
      )}

      {/* Indexing Error Display */}
      {indexingError && (
        <div className="error-display fixed left-1/2 bottom-4 transform -translate-x-1/2 px-3 py-1.5 bg-red-500 text-white rounded">
          <p className="text-sm">Error indexing data: {indexingError}</p>
          <button
            onClick={handleDismissIndexingError}
            className="mt-1 px-2 py-0.5 bg-white text-red-500 rounded text-xs"
          >
            Dismiss
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
