import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const Sidebar = ({
  periods,
  itemsCount,
  averagePrice,
  averageSurface,
  averageSurfacePrice,
  markersCount,
  setsCount,
  periodStart,
  periodEnd,
}) => {
  const svgRef = useRef(null);

  useEffect(() => {
    const svgElement = svgRef.current;

    // Clear previous chart
    d3.select(svgElement).selectAll("*").remove();

    // Process periods data to extract data points
    const data = periods
      .map((d) => {
        const xValue = (d.groupBound.start + d.groupBound.end) / 2;
        const yValue = d._count !== 0 ? d._metrics[2] / d._count : 0;
        return {
          x: new Date(xValue * 1000), // Convert to milliseconds
          y: yValue,
        };
      })
      .filter((d) => !isNaN(d.y) && isFinite(d.y));

    data.sort((a, b) => a.x - b.x);

    if (data.length === 0) {
      return;
    }

    // Set dimensions and margins
    const margin = { top: 20, right: 30, bottom: 30, left: 40 };

    // Get the actual width and height from the SVG element
    const width = svgElement.clientWidth - margin.left - margin.right;
    const height = svgElement.clientHeight - margin.top - margin.bottom;

    // Append the svg object to the ref element
    const svg = d3
      .select(svgElement)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Set the scales
    const x = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => d.x))
      .range([0, width]);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.y) * 1.3])
      .range([height, 0]);

    // Define the area with a curve
    const area = d3
      .area()
      .curve(d3.curveBasis) // Smooth the area
      .x((d) => x(d.x))
      .y0(y(0))
      .y1((d) => y(d.y));

    // Add the area path
    svg
      .append("path")
      .datum(data)
      .attr("class", "area")
      .attr("d", area)
      .attr("fill", "#c8d7d4")
      .attr("stroke", "none");

    // Add the X Axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x).ticks(5));

    // Add the Y Axis
    svg.append("g").call(d3.axisLeft(y).ticks(5));
  }, [periods]);

  return (
    <div
      className="flex flex-col p-6 sidebar"
      style={{ background: "#de9292" }}
    >
      {/* Header */}
      <h1 className="mb-12 text-2xl font-bold text-white ">Details</h1>

      {/* Statistics Section */}
      <div className="p-4 mb-6 bg-white rounded-md text-neutral-900">
        {/* Statistics List */}
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Statistique
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase"
              >
                Valeur
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td className="px-6 py-4 whitespace-nowrap">
                <strong>Ventes:</strong>
              </td>
              <td className="px-6 py-4 text-right whitespace-nowrap">
                {itemsCount}
              </td>
            </tr>
            <tr>
              <td className="px-6 py-4 whitespace-nowrap">
                <strong>Prix Moyen M²:</strong>
              </td>
              <td className="px-6 py-4 text-right whitespace-nowrap">
                {averageSurfacePrice !== null
                  ? `${averageSurfacePrice.toFixed(0)} €/M²`
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td className="px-6 py-4 whitespace-nowrap">
                <strong>Prix Moyen:</strong>
              </td>
              <td className="px-6 py-4 text-right whitespace-nowrap">
                {averagePrice !== null ? `${averagePrice.toFixed(0)} €` : "N/A"}
              </td>
            </tr>
            <tr>
              <td className="px-6 py-4 whitespace-nowrap">
                <strong>Surface Moyenne:</strong>
              </td>
              <td className="px-6 py-4 text-right whitespace-nowrap">
                {averageSurface !== null
                  ? `${averageSurface.toFixed(0)} M²`
                  : "N/A"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Chart Section */}
      <div className="p-4 bg-white rounded-md text-neutral-900">
        <h3 className="mb-4 text-lg font-semibold text-center">
          Évolution du prix au m²
        </h3>
        <svg
          className="mb-8"
          ref={svgRef}
          style={{ width: "100%" }}
          viewBox={`0 0 ${svgRef.current ? svgRef.current.clientWidth : 400} ${
            svgRef.current ? svgRef.current.clientWidth * 0.5 : 200
          }`}
          preserveAspectRatio="xMidYMid meet"
        ></svg>
      </div>
    </div>
  );
};

export default Sidebar;
