import React, { useEffect, useState } from "react";
import "./SplashScreen.css";

const SplashScreen = ({ onFinish }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onFinish();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onFinish]);

  return (
    <div className="flex flex-col splash-screen">
      <img width="300px" src="house.png"></img>
      <div>
        <h1 className="flex splash-text">
          <span>H</span>
          <span>I</span>
          <span>M</span>
          <span>O</span>
        </h1>
        <h1 className="sub-text">
          <span>Votre outil immobilier</span>
        </h1>
      </div>
    </div>
  );
};

export default SplashScreen;
