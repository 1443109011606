// use.js
import { useState, useEffect } from "react";
import Module from "./mask.js";

const useWasm = () => {
  const [wasm, setWasm] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeWasm = async () => {
      try {
        const wasmModule = await Module();

        // Wrap the C functions for use in JavaScript
        const loadPolygon = wasmModule.cwrap("load_polygon", "number", [
          "number",
        ]);
        const createTensor = wasmModule.cwrap("create_tensor", "number", [
          "number",
          "number",
          "number",
        ]);

        // Expose helper functions
        const allocateString = (str) => {
          const encoder = new TextEncoder();
          const bytes = encoder.encode(str + "\0"); // Null-terminated
          const ptr = wasmModule._malloc(bytes.length);
          wasmModule.HEAPU8.set(bytes, ptr);
          return ptr;
        };

        const allocateDoubleArray = (array) => {
          const nDataBytes = array.length * Float64Array.BYTES_PER_ELEMENT;
          const dataPtr = wasmModule._malloc(nDataBytes);
          const dataHeap = new Float64Array(
            wasmModule.HEAPU8.buffer,
            dataPtr,
            array.length
          );
          dataHeap.set(array);
          return dataPtr;
        };

        const allocateIntArray = (array) => {
          const nDataBytes = array.length * Int32Array.BYTES_PER_ELEMENT;
          const dataPtr = wasmModule._malloc(nDataBytes);
          const dataHeap = new Int32Array(
            wasmModule.HEAPU8.buffer,
            dataPtr,
            array.length
          );
          dataHeap.set(array);
          return dataPtr;
        };

        const readIntArray = (ptr, length) => {
          const dataHeap = new Int32Array(
            wasmModule.HEAPU8.buffer,
            ptr,
            length
          );
          // Copy the data into a new array
          return new Int32Array(dataHeap);
        };

        // Free memory allocated in WASM
        const free = wasmModule._free;

        setWasm({
          loadPolygon,
          createTensor,
          allocateString,
          allocateDoubleArray,
          allocateIntArray,
          readIntArray,
          free,
        });
      } catch (err) {
        console.error("Failed to load WASM module:", err);
        setError(err);
      }
    };

    initializeWasm();
  }, []);

  return { wasm, error };
};

export default useWasm;
