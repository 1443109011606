// src/components/MapScreen.js

import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import mapStyle from "../utils/mapStyle";
import useWasmMask from "../hooks/useWasmMask";

import * as tf from "@tensorflow/tfjs";
import * as d3 from "d3";

const MapScreen = ({ markers, areas, onBoundsChange }) => {
  const mapRef = useRef(null);
  const containerRef = useRef(null); // Ref for the map container
  const zoomTimeoutRef = useRef(null);
  const moveTimeoutRef = useRef(null);
  const workerRef = useRef(null);
  const areaMarkersRef = useRef(new Map()); // Use Map for efficient marker tracking

  // Constants for grid generation
  const resolution = 128;
  const max = 5000;
  const mapTransparency = 0.35;

  // Initialize bounds as null
  const [bounds, setBounds] = useState(null);
  const [geojsonPolygon, setGeojsonPolygon] = useState(null);
  const [overlayUrl, setOverlayUrl] = useState(null);
  const [wasmError, setWasmError] = useState(null);

  // State to store map container size
  const [mapSize, setMapSize] = useState({ width: 0, height: 0 });

  // Load GeoJSON polygon
  useEffect(() => {
    fetch("france.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch france.geojson");
        }
        return response.json();
      })
      .then((data) => {
        setGeojsonPolygon(data.features[0].geometry);
      })
      .catch((error) => {
        console.error("Error loading GeoJSON data:", error);
        setWasmError("Error loading GeoJSON data.");
      });
  }, []);

  // Measure map container size using ResizeObserver
  useEffect(() => {
    if (!containerRef.current) return;

    // Function to update map size
    const updateMapSize = () => {
      const { clientWidth, clientHeight } = containerRef.current;
      setMapSize({ width: clientWidth, height: clientHeight });
    };

    // Initial measurement
    updateMapSize();
  }, [areas]);

  // Calculate aspect ratio and dimensions
  const aspectRatio = useMemo(() => {
    return mapSize.height > 0 ? mapSize.width / mapSize.height : 1;
  }, [mapSize]);

  const calculatedWidth = useMemo(() => {
    return Math.round(aspectRatio * resolution);
  }, [aspectRatio, resolution]);

  // Memoize mask options to prevent unnecessary re-renders
  const maskOptions = useMemo(
    () => ({
      width: calculatedWidth,
      height: resolution,
    }),
    [calculatedWidth, resolution]
  );

  // Initialize the Web Worker
  useEffect(() => {
    workerRef.current = new Worker(
      new URL("../workers/map.worker.js", import.meta.url)
    );

    // Listen for messages from the worker
    workerRef.current.onmessage = async (event) => {
      const { data, shape } = event.data;

      const canvas = document.createElement("canvas");
      canvas.width = maskOptions.width; // Use calculated width
      canvas.height = maskOptions.height; // Fixed height (resolution)

      const typedArray = new Float32Array(data);

      // Reconstruct the tensor
      const result = tf.tensor(typedArray, shape).clipByValue(0, 1);

      try {
        // Render the tensor to the offscreen canvas using await
        await tf.browser.toPixels(result, canvas);

        result.dispose();

        // Convert the canvas content to a blob using await
        const blob = await new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Canvas toBlob failed"));
            }
          });
        });
        // Create a URL from the blob
        const url = URL.createObjectURL(blob);
        // Update the overlay URL with the newly created URL
        setOverlayUrl(url);
      } catch (error) {
        console.error("Error during processing:", error);
      }
    };

    // Cleanup function to terminate the worker when the component unmounts
    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
        workerRef.current = null;
      }
    };
  }, [maskOptions]);

  // Use the useWasmMask hook with memoized options
  const { mask } = useWasmMask(geojsonPolygon, bounds, maskOptions);

  // Send the mask array to the web worker when it is available
  useEffect(() => {
    if (mask && areas && areas.length > 0 && workerRef.current && bounds) {
      const workerData = {
        ...maskOptions,
        areas,
        bounds,
        max,
        mask,
      };

      workerRef.current.postMessage(workerData);
      // If maskArray is large, transfer it as an ArrayBuffer for performance
    }
  }, [areas, maskOptions, max]);

  const currentOverlayRef = useRef(null);
  const previousOverlayRef = useRef(null);

  const fadeOverlay = (overlay, startOpacity, endOpacity, duration = 500) => {
    const stepTime = 50; // Time between opacity steps in ms
    const steps = duration / stepTime;
    const opacityStep = (endOpacity - startOpacity) / steps;
    let currentStep = 0;

    const fadeInterval = setInterval(() => {
      currentStep++;
      const newOpacity = startOpacity + opacityStep * currentStep;
      overlay.setOpacity(newOpacity);

      if (currentStep >= steps) {
        clearInterval(fadeInterval);
        overlay.setOpacity(endOpacity); // Ensure final opacity

        if (endOpacity === 0) {
          overlay.setMap(null);
        }
      }
    }, stepTime);
  };

  useEffect(() => {
    if (!areas || areas.length === 0) {
      // Fade out the current overlay if it exists
      if (currentOverlayRef.current) {
        previousOverlayRef.current = currentOverlayRef.current;
        fadeOverlay(previousOverlayRef.current, mapTransparency, 0, 250); // Adjusted duration
      }

      // Remove all area markers
      areaMarkersRef.current.forEach((entry) => {
        entry.marker.setMap(null);
      });
      areaMarkersRef.current.clear();
    }
  }, [areas]);

  useEffect(() => {
    if (mapRef.current && overlayUrl && bounds) {
      // Fade out the current overlay if it exists
      if (currentOverlayRef.current) {
        previousOverlayRef.current = currentOverlayRef.current;
        fadeOverlay(previousOverlayRef.current, mapTransparency, 0, 250); // Adjusted duration
      }

      // Create and fade in the new overlay
      const newOverlay = new window.google.maps.GroundOverlay(overlayUrl, {
        north: bounds.maxLat,
        south: bounds.minLat,
        east: bounds.maxLng,
        west: bounds.minLng,
      });
      newOverlay.setOpacity(0);
      newOverlay.setMap(mapRef.current);
      currentOverlayRef.current = newOverlay;

      fadeOverlay(currentOverlayRef.current, 0, mapTransparency, 250); // Adjusted duration
    }
  }, [overlayUrl]); // Added maskOptions as dependency

  // Memoize GoogleMap Props to Prevent Unnecessary Re-renders
  const mapContainerStyle = useMemo(
    () => ({ width: "100%", height: "100%" }),
    []
  );

  const mapCenter = useMemo(() => {
    return {
      lat: 46.322687607,
      lng: 2.2137,
    };
  }, []);

  const mapOptions = useMemo(
    () => ({ disableDefaultUI: true, styles: mapStyle }),
    []
  );

  // Handle map load
  const onMapLoad = useCallback(
    (map) => {
      mapRef.current = map;

      // Handler for zoom changes with debounce
      const handleZoomChanged = () => {
        if (zoomTimeoutRef.current) {
          clearTimeout(zoomTimeoutRef.current);
        }
        // Set a new timeout to call onBoundsChange after 100ms of no zoom changes
        zoomTimeoutRef.current = setTimeout(() => {
          const mapBounds = mapRef.current.getBounds();
          if (mapBounds) {
            const ne = mapBounds.getNorthEast();
            const sw = mapBounds.getSouthWest();
            // Update the bounds state
            setBounds({
              minLng: sw.lng(),
              minLat: sw.lat(),
              maxLng: ne.lng(),
              maxLat: ne.lat(),
            });
            // Inform the parent component
            onBoundsChange({
              ne: { lat: ne.lat(), lng: ne.lng() },
              sw: { lat: sw.lat(), lng: sw.lng() },
            });
          }
        }, 100);
      };

      // Handler for map movements with debounce
      const handleMapMove = () => {
        if (moveTimeoutRef.current) {
          clearTimeout(moveTimeoutRef.current);
        }
        // Set a new timeout to call onBoundsChange after 100ms of no movement
        moveTimeoutRef.current = setTimeout(() => {
          const mapBounds = mapRef.current.getBounds();
          if (mapBounds) {
            const ne = mapBounds.getNorthEast();
            const sw = mapBounds.getSouthWest();
            // Update the bounds state
            setBounds({
              minLng: sw.lng(),
              minLat: sw.lat(),
              maxLng: ne.lng(),
              maxLat: ne.lat(),
            });
            // Inform the parent component
            onBoundsChange({
              ne: { lat: ne.lat(), lng: ne.lng() },
              sw: { lat: sw.lat(), lng: sw.lng() },
            });
          }
        }, 100);
      };

      map.addListener("zoom_changed", handleZoomChanged);
      map.addListener("bounds_changed", handleMapMove);

      // Initial onBoundsChange to load markers when map is first loaded
      const mapBounds = map.getBounds();
      if (mapBounds) {
        const ne = mapBounds.getNorthEast();
        const sw = mapBounds.getSouthWest();
        // Set initial bounds state
        setBounds({
          minLng: sw.lng(),
          minLat: sw.lat(),
          maxLng: ne.lng(),
          maxLat: ne.lat(),
        });
        // Inform the parent component
        onBoundsChange({
          ne: { lat: ne.lat(), lng: ne.lng() },
          sw: { lat: sw.lat(), lng: sw.lng() },
        });
      }

      // Cleanup function to remove listeners and clear timeouts
      return () => {
        window.google.maps.event.clearInstanceListeners(map);

        if (zoomTimeoutRef.current) {
          clearTimeout(zoomTimeoutRef.current);
        }
        if (moveTimeoutRef.current) {
          clearTimeout(moveTimeoutRef.current);
        }
      };
    },
    [onBoundsChange]
  );

  // Function to update area markers efficiently
  const updateAreaMarkers = useCallback(() => {
    if (mapRef.current && areas) {
      const mapBounds = mapRef.current.getBounds();
      if (!mapBounds) return;

      const markersMap = areaMarkersRef.current;
      const newMarkersMap = new Map();

      areas.forEach((area) => {
        const key = `${(
          (area.groupBound.south + area.groupBound.north) /
          2
        ).toFixed(6)},${(
          (area.groupBound.east + area.groupBound.west) /
          2
        ).toFixed(6)}`; // Unique key based on position
        const existingEntry = markersMap.get(key);
        const position = new window.google.maps.LatLng(
          area._position.lat,
          area._position.lng
        );
        const isInBounds = mapBounds.contains(position);

        if (isInBounds) {
          if (existingEntry) {
            const { marker, area: existingArea } = existingEntry;
            // Check if counts or position have changed
            if (
              existingArea._count !== area._count ||
              existingArea._position.lat !== area._position.lat ||
              existingArea._position.lng !== area._position.lng
            ) {
              // Update marker icon
              marker.setIcon(
                createAreaLabelIcon(area._count, area._metrics[2] / area._count)
              );
              // Update stored area
              existingEntry.area = area;
            }
            // Keep the marker in newMarkersMap
            newMarkersMap.set(key, existingEntry);
            // Remove from markersMap to keep track of which markers are no longer needed
            markersMap.delete(key);
          } else {
            // Create new marker
            const marker = new window.google.maps.Marker({
              position: area._position,
              map: mapRef.current,
              icon: createAreaLabelIcon(
                area._count,
                area._metrics[2] / area._count
              ),
              title: `${area._count} ventes\n${(
                area._metrics[2] / area._count
              ).toFixed(0)} €/M²\n${(area._metrics[1] / area._count).toFixed(
                0
              )} M²/vente\n${(area._metrics[0] / area._count).toFixed(
                0
              )} €/vente`,
            });
            // Store in newMarkersMap
            newMarkersMap.set(key, { marker, area });
          }
        }
      });

      // Remove markers that are no longer in areas or out of bounds
      markersMap.forEach((entry) => {
        entry.marker.setMap(null);
      });

      // Update the markers map
      areaMarkersRef.current = newMarkersMap;
    }
  }, [areas]);

  // Update markers when areas or bounds change
  useEffect(() => {
    updateAreaMarkers();
  }, [areas, bounds, updateAreaMarkers]);

  // Handle errors
  if (wasmError) {
    return <div>Error: {wasmError}</div>;
  }

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <div className="relative w-full h-full" ref={containerRef}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={mapCenter} // Provide a fallback center
        zoom={6} // Adjust zoom accordingly
        options={mapOptions}
        onLoad={onMapLoad}
      >
        {areas.length === 0 &&
          markers.map((marker, index) => (
            <Marker
              key={`${marker._position.lat},${marker._position.lng}-markers-${index}`}
              position={marker._position}
              icon={createSingleMarkerIcon(marker, max)}
              title={`REF: ${marker._id}\n\n${formatDate(
                marker._metrics[5]
              )}\n${marker._metrics[2].toFixed(
                0
              )} €/M²\n${marker._metrics[1].toFixed(
                0
              )} M²\n${marker._metrics[0].toFixed(0)} €`}
            />
          ))}

        {/* Area markers are now added programmatically and are not rendered as React components */}
      </GoogleMap>
    </div>
  );
};

// Function to map count to marker size
const getSizeByCount = (count) => {
  const minSize = 40;
  const maxSize = 100;
  const size = Math.min(maxSize, minSize + count * 5);
  return size;
};

// Create Area Label Marker Icon (for counts) with dynamic color and size
const createAreaLabelIcon = (count, averagePrice) => {
  const size = getSizeByCount(count);

  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" >
      <!-- Define the drop shadow filter -->
      <defs>
        <filter id="text-shadow" x="-250%" y="-250%" width="500%" height="500%">
          <feDropShadow 
            flood-color="rgba(0, 0, 0, 0.8)" 
            stdDeviation="10" 
          />
        </filter>
      </defs>

      <!-- Apply the filter to the text -->
      <text 
        x="50%" 
        y="50%" 
        text-anchor="middle" 
        dominant-baseline="central" 
        font-size="${size / 8}" 
        font-weight="bold" 
        font-family="Arial"
        fill="white"
        filter="url(#text-shadow)"
      >
        ${count}
      </text>
    </svg>
  `;
  return {
    url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg),
    scaledSize: new window.google.maps.Size(size, size),
    anchor: new window.google.maps.Point(size / 2, size / 2),
  };
};

// Function to create a simple circular icon for single markers
const createSingleMarkerIcon = (marker, max) => {
  const size = 6; // Size of the single marker

  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}">
      <circle cx="${size / 2}" cy="${size / 2}" r="${
    size / 2
  }" fill="${d3.interpolateYlOrRd(marker._metrics[2] / max)}"/>
    </svg>
  `;
  return {
    url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg),
    scaledSize: new window.google.maps.Size(size, size),
    anchor: new window.google.maps.Point(size / 2, size / 2),
  };
};

export default MapScreen;
